import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import RelatedBook from '../../Related/RelatedBook.vue'
import RelatedElement from '../../Related/RelatedElement.vue'
import RelatedEntry from '../../Related/RelatedEntry.vue'
import RelatedImage from '../../Related/RelatedImage.vue'
import RelatedAudio from '../../Related/RelatedAudio.vue'
import RelatedVideo from '../../Related/RelatedVideo.vue'
import CheckInService from '../../../api/CheckInService.js'
import LoginPop from '../../Login/LoginPop.vue'
import echarts from 'echarts'
import 'echarts/extension/dataTool'
import VIPLayer from '../../../components/VIPLayer.vue'

export default{
	name:'ElementDetail',
	data(){
		return{
			ElementModelInfo:'',//详情
			AnnotationList:[],//释义
			AnnotationCurIndex:0,
			ObjproList:[],//对象属性
			DataproList:[],//数据属性
			isShowImg:true,
			collectStatus:0,
			showLogin:false,
			LayerHeight:0
		}
	},
	methods:{
		GetElementModel(){ //获取详情
			var that = this
			CheckInService.GetElementModel(that.$route.query.id,that.$UserID).then(res=>{
				if(res.Tag == 1){
					if(res.Result.ElementModel.ReleaseStatus == 1){
						that.ElementModelInfo = res.Result
						that.collectStatus = res.Result.ComStatus
						if(!that.ElementModelInfo.ElementModel.cover_path){
							that.isShowImg = false
							that.GetElementAtlas()
						}
					}else{
						that.$alert('该资源未发布', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								if (action == "confirm") {
									window.close()
								}
								window.close()
							}
						});
					}
				}
			})
		},
		GetElementAtlas(){ //获取知识图谱
			var that = this
			CheckInService.GetElementAtlas(that.$route.query.id).then(res=>{
				if(res.Tag == 1){
					var myChart = echarts.init(document.getElementById('chartForce'));
					var categories = [{
							name: '知识元'
						},
						{
							name: '对象属性'
						},
						{
							name: '对象属性值'
						},
					]
					myChart.setOption({
						title: {
							text: '',
							subtext: '',
							top: 'bottom',
							x: 'left',
							y: 'top'
						},
						tooltip: {},
						series: [{
							type: 'graph',
							layout: 'force',
							ribbonType: false,
							categories: categories,
							// focusNodeAdjacency: true,
							force: { //力引导图基本配置
								repulsion: 100,
								edgeLength: 50, //边的两个节点之间的距离，这个距离也会受 repulsion。[10, 50] 。值越小则长度越长
								layoutAnimation: true
								//因为力引导布局会在多次迭代后才会稳定，这个参数决定是否显示布局的迭代动画，在浏览器端节点数据较多（>100）的时候不建议关闭，布局过程会造成浏览器假死。                        
							},
							label: {
								position: 'right',
								show: true,
								textStyle: {
									color: '#333'
								}
							},
							itemStyle: {
								borderColor: '#fff',
								borderWidth: 1,
								shadowBlur: 10,
								shadowColor: 'rgba(0, 0, 0, 0.3)'
							},
							nodeStyle: {
								brushType: 'both',
								borderColor: 'rgba(255,215,0,0.4)',
								borderWidth: 1
							},
							lineStyle: {
								color: 'source',
								curveness: 0.3,
								opacity: 1
							},
							useWorker: true, //页面加载 防止跳转太快
							minRadius: 15,
							maxRadius: 25,
							gravity: 1.1,
							scaling: 1.1,
							roam: true,
							clickable: false,
							data: res.Result.newNodeList,
							links: res.Result.newLinkList,
						}]
					})
				}
			})
		},
		GetElementAnnotationList(){ //获取释义
			var that = this
			CheckInService.GetElementAnnotationList(that.$route.query.id).then(res=>{
				if(res.Tag == 1){
					that.AnnotationList = res.Result
				}
			})
		},
		changeAnnoIndex(index){ //切换释义
			this.AnnotationCurIndex = index 
		},
		GetElementElementObjproList(){ //获取对象属性
			var that = this
			CheckInService.GetElementElementObjproList(that.$route.query.id).then(res=>{
				if(res.Tag == 1){
					that.ObjproList = res.Result
				}
			})
		},
		GetElementElementDataproList(){ //获取数据属性
			var that = this
			CheckInService.GetElementElementDataproList(that.$route.query.id).then(res=>{
				if(res.Tag == 1){
					that.DataproList = res.Result
				}
			})
		},
		showAlas(){ //切换显示图片和知识图谱
			this.isShowImg = !this.isShowImg
			if(!this.isShowImg){
				this.GetElementAtlas()
			}
		},
		InsertCollection(){ //添加收藏
			var that = this
			if(that.$UserID){
				CheckInService.InsertUserColletion(that.$route.query.id,that.ElementModelInfo.ElementModel.element_name,that.$UserID,0).then(res=>{
					if(res.Tag == 1){
						that.collectStatus = 1
						that.$alert('收藏成功', '提示', {
							confirmButtonText: '确定'
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		CancelCollection(){ //取消收藏
			var that = this
			if(that.$UserID){
				CheckInService.UpdateUserColletion(that.$UserID,that.$route.query.id).then(res=>{
					if(res.Tag == 1){
						that.collectStatus = 0
						that.$alert('取消收藏成功', '提示', {
							confirmButtonText: '确定'
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		toElementDetail(value){ //词条详情
			if(value.type == 1 || value.id){
				window.open(this.$WebUrl + 'ElementDetail?id=' + value.id)
			}
		},
		toEntryDetail(id){ //条目详情
			window.open(this.$WebUrl + 'EntryDetail?id=' + id)
		},
		toLogin(){
			this.showLogin = false
			this.showLogin = true
		}
	},
	created(){
		this.GetElementModel()
		this.GetElementElementObjproList()
		this.GetElementElementDataproList()
		this.GetElementAnnotationList()
		
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'RelatedBook':RelatedBook,
		'RelatedElement':RelatedElement,
		'RelatedEntry':RelatedEntry,
		'RelatedImage':RelatedImage,
		'RelatedAudio':RelatedAudio,
		'RelatedVideo':RelatedVideo,
		'LoginPop':LoginPop,
		'VIPLayer':VIPLayer
	}
}